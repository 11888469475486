@use '../../external-styles/bootstrap';
@use '../utils/variables';

.mobile-nav-bar {
	height: variables.$footerHeight;
	display: none;

	@include bootstrap.media-breakpoint-down(md) {
		display: flex !important;
	}
}

.app-footer {
	display: none;

	@include bootstrap.media-breakpoint-up(md) {
		display: block !important;
	}
}