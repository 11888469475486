@use '../utils/variables';
@use '../../external-styles/bootstrap/bootstrap';

.mb-chat-row {
	border-bottom: solid 1px variables.$charcoal-300;
	cursor: pointer;
	margin-right: 0;
	margin-left: 0;
	padding: 0.5rem 0.5rem 0.25rem 0.5rem;

	.mb-chat-date {
		@include bootstrap.font-size(12px);
	}
}

.mb-chat-input-container {
	padding: 0.5rem;
	background: variables.$charcoal-100;
	border-top: solid 1px variables.$gray;
}

#chatList {
	overflow: auto;
	height: calc(100vh - 108px);

	@supports (-webkit-touch-callout: none) {
		// Targets Safari on IOS
		height: calc(100dvh - 108px) !important;
	}

	.mb-flyout-body & {
		height: 100%;
	}
}

#chatOutput {
	overflow: auto;
	height: calc(100vh - 215px);
	@include bootstrap.media-breakpoint-down(md) {
		height: calc(100vh - 232px) !important;
	}

	@supports (-webkit-touch-callout: none) {
		// Targets Safari on IOS
		height: calc(100dvh - 215px) !important;

		@include bootstrap.media-breakpoint-down(md) {
			height: calc(100dvh - 232px) !important;
		}
	}
}

.chat-output-row {
	padding: 0.25rem;
	margin-bottom: 0.25rem;

	&.chat-output-agent {
		border: solid 1px variables.$charcoal-400;
		background: variables.$charcoal-50;

		.chat-output-role {
			color: variables.$blue;
		}
	}
}

.no-messages {
	display: flex;
	align-items: center;
	justify-content: center;
	@include bootstrap.font-size(24px);
	color: variables.$charcoal-400;
	height: 100%;
}

.chat-history-btn {
	border-radius: 0;
	width: 100%;
	display: flex;
	gap: 0.25rem;
	align-items: center;
	justify-content: center;
}

.active-chat {
	background: variables.$charcoal-100;

	.mb-chat-title {
		font-weight: 600;
	}
}

.chat-loader-container {
	display: flex;
	color: variables.$blue;
	font-weight: bold;

	.chat-loader, .chat-loader:before, .chat-loader:after {
		border-radius: 50%;
		width: 2.5em;
		height: 2.5em;
		animation-fill-mode: both;
		animation: bblFadInOut 1.8s infinite ease-in-out;
	}
	.chat-loader {
		color: variables.$yellow-light-1;
		font-size: 4px;
		position: relative;
		text-indent: -9999em;
		transform: translateZ(0);
		animation-delay: -0.16s;
		top: -3px;
	}
	.chat-loader:before,
	.chat-loader:after {
		content: '';
		position: absolute;
		top: 0;
	}
	.chat-loader:before {
		left: -3.5em;
		animation-delay: -0.32s;
	}
	.chat-loader:after {
		left: 3.5em;
	}

	.chat-loader-agent {
		margin-right: 1.5rem;
	}
}
  
@keyframes bblFadInOut {
	0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
	40% { box-shadow: 0 2.5em 0 0 }
}